<template>
    <Layout :title="'OrderList' | l">
        <div class="d-flex align-items-end mb-2">
            <div class="mr-2">
                <label for="search" class="control-label mb-0">{{ 'Search' | l }}</label>
                <input type="text" id="search" class="form-control" v-model="searchTerm" @input="refresh">
            </div>
            <div>
                <label for="status" class="control-label mb-0">{{ 'Status' | l }}</label>
                <select id="status" class="form-control" v-model="statusFilter" @change="refresh">
                    <option value="">{{ 'All' | l }}</option>
                    <option v-for="s in orderStatuses" :value="s.id">{{ s.text }}</option>
                </select>
            </div>
        </div>
        <Datatable v-bind="table" @sortingChanged="changeSorting"/>
        <Pagination v-bind="pagination" @change="changePage"/>
    </Layout>
</template>

<script>
    import _ from 'lodash';
    import Vue from 'vue';
    import Layout from "../../components/Layout";
    import Datatable from "../../components/Datatable";
    import Pagination from "../../components/Pagination";
    import EditableStatus from "../../components/EditableStatus";

    // On register le component comme ça pour pouvoir l'utiliser dynamiquement dans la Datatable
    // sans avoir à l'inclure directement dedans.
    Vue.component('EditableStatus', EditableStatus);

    export default {
        components: {Layout, Datatable, Pagination},
        props: {
            orderStatuses: Array,
            orders: {
                type: Array,
                required: true
            },
            pagination: {
                type: Object,
                required: true
            },
            search: String,
            status: Number
        },
        data() {
            return {
                searchTerm: this.search || '',
                statusFilter: this.status || ''
            }
        },
        computed: {
            sorting() {
                return this.pagination.sorting || 'date DESC';
            },
            table() {
                return {
                    columns: [
                        {name: 'date', displayFn: val => val.substring(0, 10)},
                        {name: 'projectNumber'},
                        {name: 'managerEmail'},
                        {
                            name: 'status',
                            component: this.statusComponent,
                            width: '250px'
                        }],
                    data: this.orders,
                    actions: [
                        {
                            key: 'view',
                            label: 'View',
                            className: 'fas fa-eye',
                            action: row => console.log('viewing', row)
                        },
                        {
                            key: 'delete',
                            label: 'Delete',
                            className: 'fas fa-trash-alt',
                            action: row => console.log('deleting', row)
                        }
                    ],
                    noDataLabel: 'NoOrders',
                    sorting: this.pagination.sorting || 'date DESC'
                };
            },
            statusComponent() {
                return {
                    name: 'EditableStatus',
                    props: {
                        statuses: this.orderStatuses,
                        onSave: (order) => {
                            return abp.services.app.order.updateStatus(_.pick(order, ['id', 'status']))
                                .then((o) => order.statusName = o.statusName);
                        }
                    }
                };
            }
        },
        methods: {
            changePage(page) {
                this.pagination.page = page;
                this.refresh();
            },
            changeSorting(sorting) {
                this.pagination.sorting = sorting;
                this.refresh();
            },
            refresh: _.throttle(function () {
                this.$inertia.replace(`?page=${this.pagination.page}&sorting=${this.sorting}&search=${this.searchTerm}&status=${this.statusFilter}`, {preserveState: true});
            }, 200),
        }
    }
</script>

<style scoped lang="scss">

</style>