<template>
  <Layout :title="'CreateEntreprise' | l">
    <div class="row">
      <EntrepriseForm
        class="col-md-8 col-xl-6"
        :entreprise="entreprise"
        :locations="locations"
        :onSave="save"
        :validations="validations"
      ></EntrepriseForm>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../components/Layout";
import EntrepriseForm from "../../components/entreprises/EntrepriseForm";
import { email, required } from "vuelidate/lib/validators";

const validations = {
  entreprise: {
    name: {
      required,
    },
    address: {
      required,
    },
    phone: {
      required,
    },
    email: {
      required,
    },
  },
};
export default {
  components: { Layout, EntrepriseForm },
  props: {
    entreprise: {
      type: Object,
      required: true,
    },
    locations: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      validations,
    };
  },
  methods: {
    save(entreprise) {
      return this.$inertia.post("/entreprises", entreprise);
    },
  },
};
</script>

<style scoped>
</style>