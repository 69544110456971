<template>
  <Layout :title="'Profil' | l">
    <div class="row">
      <UserForm
        v-bind="{ user, roles, validations,isProfile }"
        :on-save="save"
        class="col-md-10 col-xl-7"
      />
    </div>
  </Layout>
</template>

<script>
import Layout from "../../components/Layout";
import UserForm from "../../components/users/UserForm";
import { email, required } from "vuelidate/lib/validators";

const validations = {
  user: {
    name: {
      required,
    },
    surname: {
      required,
    },
    userName: {
      required,
    },
    emailAddress: {
      required,
      emailValidation: email,
    },
    roleNames: {
      atLeastOneRole: required,
    },
  },
};

export default {
  components: { Layout, UserForm },
  props: {
    user: {
      required: true,
      type: Object,
    },
    roles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return { 
        validations,
        isProfile: true
     };
  },
  methods: {
    save(user) {
      return this.$inertia.put(`/users/profile/${user.id}`, user);
    }
  },
};
</script>

<style scoped>
</style>