<template>
  <div class="row">
    <div class="col-xl-9">
      <Panel :title="'ApplicantInformation' | l">
        <form action="">
          <div class="row mb-3">
            <div class="col">
              <div class="text-muted font-italic">
                {{ "AllFieldsRequired" | l }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-md">
              <label for="entrepriseName">{{ "EntrepriseName" | l }}</label>
              <vSelect
                label="name"
                :options="request.entreprises"
                :reduce="(entreprise) => entreprise.id"
                :value="request.entrepriseId"
                @input="setSelectedEntreprise"
                :disabled="!hasPermission"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!request.entrepriseId"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </vSelect>
            </div>

            <div class="form-group col-md">
              <label for="date">{{ "Date" | l }}</label>
              <TextInput :type="'date'" id="date" :value="$v.request.date" />
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md">
              <label for="locationId" class="control-label">
                {{ "Localisation" | l }}
              </label>
              <vSelect
                label="name"
                required
                :options="getLocationByEntrepriseSelected"
                :reduce="(location) => location.id"
                :value="request.locationId"
                @input="setSelectedLocalisation"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!request.locationId"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
                <template #option="{ name, address }">
                  {{ name }}
                  <br />
                  <cite>{{ address }}</cite>
                </template>
              </vSelect>
              <span class="text-danger" v-if="request.locationId <= 0"
                >Ce champ est requis</span
              >
            </div>
          </div>
        </form>
      </Panel>

      <Panel :title="'Products' | l" class="mt-3">
        <div class="product-line">
          <div class="row">
            <div class="col-3">
              <label for="productSelected">{{ "ProductSelected" | l }}</label>
              <vSelect
                label="name"
                :options="products"
                :reduce="(product) => product.id"
                :value="productSelected.productId"
                @input="setSelectedProduct"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!productSelected.productId"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
                <template #option="{ name, masterCategory }">
                  {{ name }}
                  <br />
                  <cite>{{ masterCategory.value }}</cite>
                </template>
              </vSelect>
              <span class="text-danger" v-if="productSelected.productId <= 0"
                >Ce champ est requis</span
              >
            </div>

            <div class="col-3">
              <div class="form-group col-md">
                <label for="numberProduct">{{ "NumberProduct" | l }}</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="productSelected.quantity"
                  min="0"
                  oninput="validity.valid||(value='');"
                />
              </div>
            </div>
            <div class="mt-auto mb-auto">
              <button
                :disabled="!canAddToProducts"
                v-on:click="addProductToRequest"
                class="btn btn-success"
              >
                Ajouter à la requête
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <table class="table table-borderless">
              <thead class="thead-light">
                <tr>
                  <th>{{ "Category" | l }}</th>
                  <th>{{ "Quantity" | l }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="request.products.length <= 0">
                  <td colspan="2">{{ "NoProductSelected" | l }}</td>
                </tr>
                <tr v-for="(p, i) in $v.request.products.$each.$iter" :key="i">
                  <td>{{ p.$model.name }}</td>
                  <td width="100">
                    <TextInput
                      fieldName="quantity"
                      :value="p.quantity"
                    ></TextInput>
                  </td>
                  <td class="text-right">
                    <button
                      class="btn btn-sm btn-danger"
                      @click="removeProduct(p.$model)"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <ErrorMessage :value="$v.request.products" />
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="form-group col-md">
              <label for="degreeOfUrgency">{{ "DegreeOfUrgency" | l }}</label>

              <vSelect
                label="name"
                :options="request.degreeOfUrgencies"
                :reduce="(degreeOfUrgency) => degreeOfUrgency.id"
                :value="request.degreeOfUrgencyId"
                @input="setSelectedDegreeOfUrgency"
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!request.degreeOfUrgencyId"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
                <template #option="{ name, note }">
                  {{ name }}
                  <br />
                  <cite v-if="note != ''">
                    <span class="badge badge-warning">{{ note }} </span></cite
                  >
                </template>
              </vSelect>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="comment">{{ "Comment" | l }}</label>
              <textarea
                class="form-control"
                name="comment"
                id="comment"
                cols="30"
                rows="5"
                v-model="request.comment"
              ></textarea>
            </div>
          </div>
        </div>

        <ErrorMessage :value="$v" />
      </Panel>

      <Panel
        v-if="hasPermission && request.id > 0"
        :title="'Status' | l"
        class="mt-3"
      >
        <div class="row">
          <div class="col-5 ml-auto">
            <div class="form-group col-md mx-0 px-0">
              <label for="status">{{ "Status" | l }}</label>
              <select id="status" class="form-control" v-model="statusSelect">
                <option
                  v-for="c in request.statuts.listStatut"
                  :key="c.id"
                  :value="c.id"
                >
                  {{ c.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <StatusForm
          :statusId="statusSelect"
          :requestId="request.id"
          :statutLog="statutLog"
        ></StatusForm>
      </Panel>

      <div class="float-right mt-3">
        <button
          v-if="statusSelect <= 2"
          class="btn btn-danger mr-2"
          @click="cancelRequest"
        >
          {{ "CancelRequest" | l }}
        </button>
        <inertia-link href="/requests" class="btn btn-secondary mr-2">{{
          "Cancel" | l
        }}</inertia-link>
        <button class="btn btn-primary" @click="save">
          {{ "sendRequest" | l }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Panel from "../../components/Panel.vue";
import { required, minValue } from "vuelidate/lib/validators";
// import NumberInput from "../NumberInput";
import TextInput from "../TextInput";

import ErrorMessage from "../ErrorMessage";
import { InertiaLink } from "@inertiajs/inertia-vue";
import StatusForm from "./StatusForm";
import vSelect from "vue-select";

export default {
  components: {
    Panel,
    ErrorMessage,
    // NumberInput,
    TextInput,
    vSelect,
    InertiaLink,
    StatusForm,
  },
  props: {
    request: {
      type: Object,
      default: () => ({}),
    },
    products: {
      type: Array,
      default: () => [],
    },
    statutLog: {
      type: Object,
      default: () => ({}),
    },
    onSave: {
      type: Function,
      default: () => {},
    },
    validations: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      statusSelect: 0,
      productSelected: {
        productId: {},
        productName: {},
        quantity: {},
      },
      hasPermission: abp.auth.hasPermission("ProductManagement"),
      noteDegree: {},
    };
  },
  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      if (!this.canAddToRequest) return;

      return this.onSave(this.request);
    },
    setSelectedEntreprise(value) {
      this.request.entrepriseId = value;
      this.setSelectedLocalisation({});
    },
    setSelectedLocalisation(value) {
      this.request.locationId = value;
    },
    setSelectDegreeOfUrgencyNote(value) {
      this.noteDegree = value;
    },
    setSelectedDegreeOfUrgency(value) {
      if (this.request.degreeOfUrgencyId != value)
        this.request.degreeOfUrgencyId = value;
    },
    setSelectedProduct(value) {
      this.productSelected.productId = value;
    },

    addProductToRequest() {
      if (!this.canAddToProducts) return;
      const id = this.productSelected.productId;
      const findproduct = _.find(this.products, (op) => op.id === id);

      const name = findproduct.name;
      const quantity = parseInt(this.productSelected.quantity, 10);

      let product = {
        id: id,
        name: name,
        quantity: quantity,
      };

      this.addProduct(product);
    },
    addProduct(product) {
      if (this.productExists(product.id))
        this.addQuantity(product.id, product.quantity);
      else this.request.products.push(product);
    },
    productExists(productId) {
      return !!_.find(this.request.products, (op) => op.id === productId);
    },
    addQuantity(productId, quantity) {
      const product = _.find(
        this.request.products,
        (op) => op.id === productId
      );

      if (product) product.quantity += quantity;
    },
    removeProduct(product) {
      this.request.products = _.filter(
        this.request.products,
        (p) => p !== product
      );
    },
    setDateFormat(value) {
      if (value != null) {
        var d = new Date(value),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        value = [year, month, day].join("-");
        return value;
      }
    },
    checkStatutSelect() {
      var statutId = this.statusSelect;
      if (this.statutLog.creationDate === null) {
        return 1; // statut 1
      }
      if (this.statutLog.pickUpBy === null) {
        return 2;
      }
      if (this.statutLog.pickUpDate === null) {
        return 3;
      }
      if (
        this.statutLog.arrivedDate === null ||
        this.statutLog.jobNumber === null
      ) {
        if (this.statutLog.cancelDate != null) {
          return 5;
        }
        return 4;
      }
    },
    cancelRequest() {
      return this.$inertia.post(`/requests/cancel/${this.request.id}`);
    },
  },
  beforeMount() {
    this.request.date = this.setDateFormat(this.request.date);
    this.statusSelect = this.checkStatutSelect();
  },
  computed: {
    selectedProduct() {
      if (this.request.productIds) {
        return _.find(this.products, (p) =>
          this.request.productIds.includes(p.id)
        );
      }
      return false;
    },
    selectedDegreeOfUrgency() {
      if (this.request.degreeOfUrgencyId) {
       return _.find(
          this.request.degreeOfUrgencies,
          (p) => p.id === this.request.degreeOfUrgencyId
        );
      }
      return false;
    },
    selectedLocalisation() {
      if (this.request.locationId) {
        return _.find(
          this.request.locations,
          (p) => p.id === this.request.locationId
        );
      }
    },
    canAddToRequest() {
      return (
        !!this.selectedLocalisation &&
        !!this.selectedDegreeOfUrgency
      );
    },
    canAddToProducts() {
      return (
        !!this.productSelected.productId && this.productSelected.quantity > 0
      );
    },

    getLocationByEntrepriseSelected() {
      var entrepriseSelectId = this.request.entrepriseId;
      var returnLocation = [];
      _.forEach(this.request.locations, (location) => {
        if (entrepriseSelectId === location.entrepriseId) {
          returnLocation.push(location);
        }
      });
      return returnLocation;
    },
  },

  validations() {
    return this.validations;
  },
};
</script>

<style scoped>
td {
  vertical-align: middle;
}
</style>
