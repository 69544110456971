<template>
    <div>
        <table class="table table-striped table-hover table-bordered dataTable">
            <thead>
            <tr>
                <th v-for="col in columns" :key="getColumnLabel(col)" :width="col.width"
                    @click="setSorting(col)">
                    <div class="d-flex justify-content-between">
                        {{ getColumnLabel(col) | l }} <i v-if="col.isSortable"  :class="['fas', `fa-${getSortClass(col)}`, { 'text-muted': getSortClass(col) === 'sort' }]"></i>
                    </div>
                </th>
                <th class="text-center" v-if="hasActions">
                    {{ 'Actions' | l }}
                </th>
            </tr>
            </thead>

            <tbody v-if="isLoading">
            <tr>
                <td class="text-center" :colspan="columnCount">
                    <i class="fa fa-refresh fa-2x fa-spin"></i>
                </td>
            </tr>
            </tbody>

            <tbody v-if="hasData && !isLoading">
            <tr v-for="row in data" :key="row.id">
                <td v-for="col in columns" :key="`${getColumnLabel(col)}-${row.id}`">
                    <div v-if="col.component">
                        <component :is="col.component.name" v-bind="col.component.props" :row="row"></component>
                    </div>
                    <span v-else v-html="getCellData(row, col)"></span>
                </td>
                <td class="datatable-actions" v-if="hasActions">
                    <span class="datatable-icon" v-for="action in actions" :key="action.key" :title="action.label">
                        <i :class="[action.className]" v-if="action.action" @click="action.action(row)"></i>
                        <a v-if="action.link" :href="action.link(row)" target="_blank"><i :class="action.className"></i></a>
                        <inertia-link v-if="action.href" :href="action.href(row)">
                            <i :class="action.className"></i>
                        </inertia-link>
                    </span>
                </td>
            </tr>
            </tbody>

            <tbody v-if="loaded && !hasData">
            <tr>
                <td class="text-center" :colspan="columnCount">
                    {{ noDataLabel | l }}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { l } from "../localization";

    export default {
        props: {
            columns: {
                type: Array
            },
            actions: {
                type: Array,
                default: () => []
            },
            data: {
                type: Array,
            },
            noDataLabel: {
                type: String,
                default: 'NoData'
            },
            sorting: {
                type: String
            }
        },
        data() {
            const firstSortableColumn = _.find(this.columns, c => this.canSort(c));
            const [name = firstSortableColumn.name, sortOrder = 'ASC'] = this.sorting.split(' ');

            return {
                sortColumn: _.find(this.columns, c => c.name === name),
                sortOrder,
                isLoading: false,
                loaded: this.initialData !== undefined,
            }
        },
        computed: {
            hasActions() {
                return this.actions.length > 0;
            },

            columnCount() {
                return this.columns.length + (this.hasActions ? 1 : 0);
            },

            hasData() {
                return this.data.length > 0;
            }
        },
        methods: {
            canSort(col) {
                return col.sortable !== false
            },

            getColumnLabel(col) {
                let key;

                if (_.isString(col))
                    key = col;
                else if (_.isObject(col))
                    key = col.label || col.name;

                return _.upperFirst(key);
            },

            getCellData(row, col) {
                if (_.isString(col))
                    return row[col];

                if (_.isObject(col)) {
                    let value = _.get(row, col.name);

                    if (col.displayFn)
                        value = col.displayFn(value, row);

                    if (typeof value === "boolean") return l(value.toString());
                    
                    return value;
                }

                return null;
            },

            getSortClass(col) {
                if (!this.canSort(col)) return '';

                if (col.name !== this.sortColumn.name)
                    return 'sort';

                if (this.sortOrder === 'ASC')
                    return 'sort-up';

                return 'sort-down';
            },

            reverseSortOrder() {
                const reverseOrders = {ASC: 'DESC', DESC: 'ASC'};
                this.sortOrder = reverseOrders[this.sortOrder];
            },

            setSorting(col) {
                console.log(col);
                if(!col.isSortable) return;
                this.reverseSortOrder();

                this.sortColumn = col;
                this.$emit('sortingChanged', `${col.name} ${this.sortOrder}`)
            },
        },
    }
</script>

<style scoped lang="scss">
    /*@import "dataTables.css";*/

    .datatable-actions {
        text-align: center;
    }

    .datatable-icon {
        font-size: 20px;
        cursor: pointer;

        & + .datatable-icon {
            margin-left: 15px;
        }

        a {
            color: black;

            &:hover {
                color: black;
            }
        }
    }
</style>