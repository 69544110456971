<template>
  <div>
    <input
      :type="type"
      class="form-control"
      v-model="value.$model"
      :class="{ 'is-invalid': value.$error }"
      @input="$emit('input', $event.target.value)"
      v-bind="$attrs"
    />
    <ErrorMessage
      :value="value"
      :fieldName="this.$attrs.fieldName || this.$attrs.id"
    />
  </div>
</template>

<script>
import ErrorMessage from "./ErrorMessage";
export default {
  inheritAttrs: false,
  components: { ErrorMessage },
  props: {
    value: Object,
    type: {
      type: String,
      default: "text",
    },
  },
  methods: {
   
  },
};
</script>

<style scoped>
</style>