<template>
    <button :class="btnClass" @click="click" :disabled="isLoading">
        <i class="fas fa-sync-alt fa-spin mr-2" v-if="isLoading"></i>
        <slot></slot>
    </button>
</template>

<script>
    export default {
        props: {
            onClick: {
                type: Function,
                required: true
            },
            btnClass: {
                type: String,
                default: 'btn btn-primary'
            }
        },
        data() {
            return {
                isLoading: false
            }
        },
        methods: {
            click() {
                const promise = this.onClick();
                if (!promise) return;

                this.isLoading = true;
                promise
                    .then(() => this.isLoading = false)
                    .catch(() => this.isLoading = false);
            }
        }
    }
</script>

<style scoped>
</style>
