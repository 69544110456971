<template>
    <div>
        <Panel :title="'Category' | l">
            <div class="mb-3 text-muted font-italic">{{ 'StarFieldsRequired' | l }}</div>
            <div class="form-group">
                <ItemSelect dictionary="MasterCategories" :items="masterCategories" v-model="category.masterCategory"
                            label="MasterCategory" newItemLabel="NewMasterCategoryName"
                            :hasError="$v.category.masterCategory.$error"/>
                <ErrorMessage field-name="MasterCategory" :value="$v.category.masterCategory"/>
            </div>

            <div class="form-group">
                <label for="categoryName" class="control-label">{{ 'CategoryName' | l }}*</label>
                <TextInput id="categoryName" :value="$v.category.name"/>
            </div>

            <!-- TODO :: Décommenter si produis ce fait ajouter -->
            <!-- <div class="form-group">
                <ImageField label="Image" :imagePath.sync="category.imagePath" folderName="products"/>
            </div> -->
        </Panel>
          <!-- TODO :: Décommenter si produis ce fait ajouter -->
            <!-- <Panel :title="'Products' | l" class="mt-3">
            <table class="table">
                <thead>
                <tr>
                    <th>Code*</th>
                    <th>Description*</th>
                    <th>
                        <button class="btn btn-sm btn-primary" @click="addProduct">
                            <i class="fas fa-plus"></i>
                        </button>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(p, i) in $v.mutProducts.$each.$iter" :key="i">
                    <td>
                        <TextInput fieldName="code" :value="p.code" @input="productCodeChanged(p.$model)"/>
                    </td>
                    <td>
                        <TextInput fieldName="description" :value="p.description"/>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-danger" @click="() => removeProduct(p.$model)">
                            <i class="fas fa-times"></i>
                        </button>
                    </td>
                </tr>
                <tr v-if="!mutProducts.length">
                    <td colspan="3" style="text-align: center">{{ 'NoProduct' | l }}</td>
                </tr>
                </tbody>
            </table>
            <ErrorMessage :value="$v.mutProducts"/>
        </Panel> -->
       
        <div class="float-right mt-3">
            <inertia-link class="btn btn-secondary mr-2" href="/products">{{ 'Cancel' | l }}</inertia-link>
            <LoadingButton :onClick="save">{{ 'Save' | l }}</LoadingButton>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import Panel from "../../components/Panel";
    import ItemSelect from "../core/dictionary/ItemSelect";
    import ImageField from "../core/ImageField";
    import TextInput from "../TextInput"; 

    import {required} from 'vuelidate/lib/validators';
    import ErrorMessage from "../ErrorMessage";
    import LoadingButton from "../LoadingButton";

    export default {
        components: {LoadingButton, ErrorMessage, 
        // ImageField, // TODO :: Décommenter si produis ce fait ajouter
        ItemSelect, Panel, 
        TextInput 
        },
        props: {
            masterCategories: {
                type: Array,
                required: true
            },
            category: {
                type: Object,
                required: true
            },
            onSave: {
                type: Function,
                default: () => {
                }
            }
        },
        //TODO :: Décommenter si produis ce fait ajouter
        // data() {
        //      return {
        //          mutProducts: this.category.products
        //      }
        // },
        methods: {
             //TODO :: Décommenter si produis ce fait ajouter
            // addProduct() {
            //     this.mutProducts.push({
            //         code: '',
            //         description: ''
            //     });
            // },
            // removeProduct(product) {
            //     this.mutProducts = _.filter(this.mutProducts, p => p !== product);
            // },
            save() {
                this.$v.$touch();
                if (this.$v.$invalid) return;

                return this.onSave({
                    ...this.category,
                     //TODO :: Décommenter si produis ce fait ajouter
                    // products: this.mutProducts
                });
            },
             //TODO :: Décommenter si produis ce fait ajouter
            // productCodeChanged(product) {
            //     this.$set(product, 'isCodeDuplicate', false);
            //     let currentIds = _.map(this.products, 'id');

            //     return abp.services.app.product.isCodeUnique(product.code, currentIds)
            //         .then(result => product.isCodeDuplicate = !result);
            // },
        },
        validations: {
            category: {
                masterCategory: {
                    required({ value }) {
                        return !!value;
                    }
                },
                name: {required},
            },
             //TODO :: Décommenter si produis ce fait ajouter
            // mutProducts: {
            //     atLeastOneProduct: required,
            //     $each: {
            //         code: {
            //             required,
            //             productCodeUnique(code, product) {
            //                 return !product.isCodeDuplicate && !_.some(this.mutProducts, p => p !== product && p.code === code);
            //             }
            //         },
            //         description: { required }
            //     }
            // }
        }
    }
</script>

<style scoped lang="scss">

</style>
