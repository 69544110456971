<template>
    <div class="d-flex flex-column">
        <header id="page-header" class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
                <i class="fas fa-bars" @click="toggleMenu"></i>
                <div class="logo-wrapper">
                    <img src="/img/logo.png" alt="Cascades" class="logo">
                </div>
            </div>
            <div>
                <a href="#">
                    Support
                    <i class="fas fa-question-circle"></i>
                </a>
                <a href="/Account/Logout">Déconnexion</a>
            </div>
        </header>
        <div class="d-flex wrapper">
            <Menu v-if="showMenu"/>
            <div class="content-wrapper">
                <div v-if="title" class="content-header">
                    <h1>{{ title }}</h1>
                </div>
                <div class="content">
                    <slot/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Menu from "./Menu";
    import {InertiaLink} from '@inertiajs/inertia-vue';

    export default {
        components: {Menu, InertiaLink},
        props: {
            title: String
        },
        data() {
            let showMenu = localStorage.getItem('showMenu') === 'true';
            return {showMenu};
        },
        methods: {
            toggleMenu() {
                this.showMenu = !this.showMenu;
                localStorage.setItem('showMenu', this.showMenu);
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../assets/_variables.scss';
    .wrapper {
        min-height: calc(100vh - 60px);
    }

    .content-wrapper {
        flex: auto;
        background-color: #F0F3F3;

        .content-header {
            height: 60px;
            padding-left: 20px;
            color: #424856;
            background-color: #F6F8F8;

            border-bottom: 2px solid darken(#F6F8F8, 10%);

            font-family: Oswald, serif;
            letter-spacing: 0.025em;

            h1 {
                line-height: 60px;
                font-size: 20px;
                text-transform: uppercase;
            }
        }

        .content {
            padding: 15px;
            height: calc(100% - 60px);
        }
    }
</style>