<template>
  <Layout :title="'CreateLocation' | l">
    <div class="row">
      <LocationForm
        class="col-md-8 col-xl-6"
        :location="location"
        :entreprises="entreprises"
        :onSave="save"
        :validations="validations"
      ></LocationForm>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../components/Layout";
import LocationForm from "../../components/locations/LocationForm";
import { email, required, minValue } from "vuelidate/lib/validators";

const validations = {
  location: {
    name: {
      required,
    },
    address: {
      required,
    },
    town: {
      required,
    },
    postalCode: {
      required,
    },
    province: {
      required,
    },
    country: {
      required,
    },
    entrepriseId : {
      required,
    }
  },
};
export default {
  components: { Layout, LocationForm },
  props: {
    location: {
      type: Object,
      required: true,
    },
    entreprises: {
      type : Array,
      required: true,
    }
  },
  data() {
    return {
      validations,
    };
  },
  methods: {
    save(location) {
      return this.$inertia.post("/locations", location);
    },
  },
};
</script>

<style scoped>
</style>