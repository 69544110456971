<template>
    <div class="panel">
        <div class="panel-title" v-if="title">
            {{ title }}
        </div>
        <div class="panel-content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String
    }
}
</script>

<style scoped lang="scss">
    @import '../assets/variables';
    
    .panel {
        background-color: white;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

        .panel-title {
            padding: 12px;
            font-size: 18px;
            font-family: Oswald,serif;
            color: $primary-color;
            text-transform: uppercase;
            border-bottom: 2px solid darken(#F6F8F8, 10%);
        }

        .panel-content {
            padding: 20px;
            font-family: Cabin,serif;
            
            .divider {
                margin: 15px -20px;

                border-bottom: 2px solid darken(#F6F8F8, 10%);
            }
        }
    }
</style>