<template>
    <div>
        <div v-if="!editing" class="d-flex justify-content-between">
            {{ row.statusName }}
            <i v-if="saving" class="fas fa-sync-alt fa-spin align-self-center"></i>
            <i v-else class="fas fa-pencil-alt align-self-center" @click="editing = true"></i>
        </div>
        <div v-else>
            <div class="input-group">
                <select class="form-control" v-model="row.status">
                    <option v-for="s in statuses" :key="s.id" :value="s.id">
                        {{ s.text }}
                    </option>
                </select>
                <div class="input-group-append">
                    <button class="btn btn-primary" @click="save">
                        <i class="fas fa-save"></i>
                    </button>
                    <button class="btn btn-secondary" @click="cancel">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            row: Object,
            statuses: Array,
            onSave: {
                type: Function,
                default: (row) => {}
            }
        },
        data() {
            return {
                editing: false,
                saving: false,
                previousStatus: this.row.status
            }
        },
        methods: {
            save() {
                this.editing = false;
                this.saving = true;
                this.onSave(this.row)
                    .then(() => this.saving = false);
            },
            cancel() {
                this.editing = false;
                this.row.status = this.previousStatus;
            }
        }
    }
</script>

<style scoped>
    .fas {
        cursor: pointer;
    }
</style>