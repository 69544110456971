<template>
  <div>
    <Panel :title="'BaseInformation' | l">
      <div v-if="isAdmin">
        <label for="entrepriseSelect" class="control-label">{{
          "EntrepriseSelect" | l
        }}</label>
        <vSelect
          label="name"
          :options="entreprises"
          :reduce="(entreprise) => entreprise.id"
          :value="location.entrepriseId"
          @input="setSelectedEntreprise"
          placeholder
        >
          <template #search="{ attributes, events }">
            <input
              class="vs__search"
              :required="!entrepriseSelected"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </vSelect>
        <span class="text-danger" v-if="location.entrepriseId <= 0"
          >Ce champ est requis</span
        >
      </div>

      <div class="form-group">
        <label for="LocationName" class="control-label">{{
          "LocalisationName" | l
        }}</label>
        <TextInput id="LocationName" :value="$v.location.name" />
      </div>

      <div class="form-group">
        <label for="address" class="control-label">{{ "Address" | l }}</label>
        <TextInput id="address" :value="$v.location.address" />
      </div>

      <div class="form-group">
        <label for="town" class="control-label">{{ "Town" | l }}</label>
        <TextInput
          type="tel"
          id="town"
          :value="$v.location.town"
          autocomplete="off"
        />
      </div>

      <div class="row">
        <div class="col-4 p-0">
          <div class="form-group col-md">
            <label for="postalCode">{{ "PostalCode" | l }}</label>
            <TextInput
              id="postalCode"
              :value="$v.location.postalCode"
            ></TextInput>
          </div>
        </div>
        <div class="col-4 p-0">
          <div class="form-group col-md">
            <label for="province">{{ "Province" | l }}</label>
            <TextInput id="province" :value="$v.location.province"></TextInput>
          </div>
        </div>
        <div class="col-4 p-0">
          <div class="form-group col-md">
            <label for="country">{{ "Country" | l }}</label>
            <TextInput id="country" :value="$v.location.country"></TextInput>
          </div>
        </div>
      </div>

      <!-- <div class="form-group col-md p-0">
               <label for="coordonateGoogleMap">{{ "CoordonateGoogleMap" | l }}</label>
              <TextInput id="coordonateGoogleMap" :value="$v.location.coordonateGoogleMap"></TextInput>
            </div> 
            <button @click="getLocation()">Test</button> -->

      <div class="clearfix">
        <LoadingButton btnClass="btn btn-primary float-right" :onClick="getLocation">
          {{ "Save" | l }}
        </LoadingButton>
      </div>
    </Panel>

    <!-- <ResetPasswordForm v-if="canChangePassword" :userId="user.id" /> -->
  </div>
</template>

<script>
import Panel from "../../components/Panel";
import LoadingButton from "../LoadingButton";
import TextInput from "../TextInput";
import ErrorMessage from "../ErrorMessage";
// import ResetPasswordForm from "./ResetPasswordForm";
//Src : https://vue-select.org/
import vSelect from "vue-select";
import axios from "axios";

export default {
  components: {
    // ResetPasswordForm,
    ErrorMessage,
    Panel,
    LoadingButton,
    TextInput,
    vSelect,
  },
  props: {
    location: {
      type: Object,
      default: () => ({}),
    },
    entreprises: {
      type: Array,
      default: () => [],
    },
    onSave: {
      type: Function,
      default: () => {},
    },
    validations: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    var admin = false;
    if (abp.auth.hasPermission("UserManagement")) {
      admin = true;
    }
    return {
      isAdmin: admin,
      entrepriseSelected: "",
    };
  },
  methods: {
    setSelectedEntreprise(value) {
      this.location.entrepriseId = value;
    },
    isEntrepriseSelect() {
      return this.location.entrepriseId;
    },
    save() {
       this.$v.$touch();
          if (this.$v.$invalid) return;
          return this.onSave(this.location);
    },
    getLocation() {
      var apiKey = "AIzaSyDgatDroCN6-dbfqNDYo0u6fE4YQPE7pl0";
      var address = `${this.location.address} ${this.location.town} ${this.location.postalCode} ${this.location.province} ${this.location.country}`;

      // window.open(`https://www.google.com/maps/search/?api=1&query=46.06553280000001+-71.96262410000001`, '_blank');

      // Vas chercher tous les information relier a la localisation
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`
        )
        .then(response => {
          // handle success
          console.log(response);
          var lat = response.data.results[0].geometry.location.lat;
          var long = response.data.results[0].geometry.location.lng;
          this.location.lat =lat;
          this.location.lng =long;
          this.location.coordonateGoogleMap = `https://www.google.com/maps/search/?api=1&query=${lat}+${long}`;

         this.save();
        });
        

      // Fait une recherche sur l'endroit dans google maps
      // axios.get(`https://www.google.com/maps/search/?api=1&query=${address}`).then(function (response) {
      //     // handle success
      //     console.log(response);
      // });

      // Reverse https://maps.googleapis.com/maps/api/geocode/json?latlng=40.714224,-73.961452&key=YOUR_API_KEY
    },
  },
  validations() {
    return this.validations;
  },
};
</script>

<style scoped lang="scss">
</style>
