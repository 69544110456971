<template>
  <Layout :title="'Editentreprise' | l">
    <div class="row">
      <EntrepriseForm
        v-bind="{ entreprise, roles, validations }"
        :on-save="save"
        class="col-md-10 col-xl-7"
      />
    </div>
  </Layout>
</template>

<script>
import Layout from "../../components/Layout";
import EntrepriseForm from "../../components/entreprises/EntrepriseForm";
import { email, required } from "vuelidate/lib/validators";

const validations = {
  entreprise: {
    name: {
      required,
    },
    address: {
      required,
    },
    phone: {
      required,
    },
    email: {
      required,
    },
  },
};

export default {
  components: { Layout, EntrepriseForm },
  props: {
    entreprise: {
      required: true,
      type: Object,
    },
    localisations: {
      type: Array,
      required: false,
    },
  },
  data() {
    return { validations };
  },
  methods: {
    save(entreprise) {
      return this.$inertia.put(`/entreprises/${entreprise.id}`, entreprise);
    },
  },
};
</script>

<style scoped>
</style>