<template>
    <div class="row">
        <div class="col-xl-9">
            <Panel :title="'ApplicantInformation' | l">
                <form action="">
                    <div class="row mb-3">
                        <div class="col">
                            <div class="text-muted font-italic">{{ 'AllFieldsRequired' | l }}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md">
                            <label for="projectNumber">{{ 'ProjectNumber' | l }}</label>
                            <TextInput id="projectNumber" :value="$v.order.projectNumber"></TextInput>
                        </div>
                        <div class="form-group col-md">
                            <label for="projectManager">{{ 'ProjectManager' | l }}</label>
                            <TextInput id="projectManager" :value="$v.order.managerEmail"></TextInput>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md">
                            <label for="applicantName" class="control-label">
                                {{ 'ApplicantName' | l }}
                            </label>
                            <TextInput id="applicantName" :value="$v.order.applicantName"></TextInput>
                        </div>
                        <div class="form-group col-md">
                            <label for="phone" class="control-label">
                                Téléphone
                            </label>
                            <TextInput id="phone" :value="$v.order.phone"></TextInput>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <label for="address" class="control-label">
                                Adresse
                            </label>
                            <TextInput id="address" :value="$v.order.address"></TextInput>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-4">
                            <label for="city" class="control-label">
                                Ville
                            </label>
                            <TextInput id="city" :value="$v.order.city"></TextInput>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="postalcode" class="control-label">
                                Code Postal
                            </label>
                            <TextInput id="postalCode" :value="$v.order.postalCode"></TextInput>
                        </div>
                        <div class="form-group col-md">
                            <label for="province" class="control-label">
                                Province
                            </label>
                            <TextInput id="province" :value="$v.order.state"></TextInput>
                        </div>
                        <div class="form-group col-md">
                            <label for="country" class="control-label">
                                Pays
                            </label>
                            <TextInput id="country" :value="$v.order.country"></TextInput>
                        </div>
                    </div>
                </form>
            </Panel>

            <Panel :title="'Products' | l" class="mt-3">
                <div class="row">
                    <div class="col-md-4" style="padding-bottom: 2rem">
                        <div class="control-label mb-2">{{ 'Preview' | l }}</div>
                        <div class="w-100 h-100 d-flex align-items-center justify-content-center" style="border: 1px solid rgba(0, 169, 80, .25)">
                            <img v-if="selectedCategory" :src="selectedCategory.imagePath" :alt="selectedCategory.name">
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-12 form-group">
                                <label for="masterCategoryId" class="control-label">{{ 'MasterCategory' | l }}</label>
                                <select id="masterCategoryId" class="form-control" v-model="masterCategoryId">
                                    <option v-for="c in masterCategories" :key="c.id" :value="c.id">{{ c.value }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 form-group">
                                <label for="category" class="control-label">{{ 'Category' | l }}</label>
                                <select id="category" class="form-control" v-model="categoryId">
                                    <option v-for="c in categoryOptions" :key="c.id" :value="c.id">{{ c.name }}</option>
                                </select>
                            </div>
                            <div class="col-12 form-group">
                                <label for="product" class="control-label">{{ 'Product' | l }}</label>
                                <select id="product" class="form-control" v-model="productId">
                                    <option v-for="p in productOptions" :key="p.id" :value="p.id"> {{ p.code + ' - ' + p.description }}</option>
                                </select>
                            </div>
                            <div class="col-4 form-group">
                                <label for="quantity" class="control-label">{{ 'Quantity' | l }}</label>
                                <input id="quantity" type="text" class="form-control" v-model="quantity">
                            </div>
                            <div class="col-8 d-flex align-items-end pb-3">
                                <button class="btn btn-block btn-primary" :disabled="!canAddToOrder" @click="addToOrder">{{ 'AddToOrder' | l }}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="form-group col-md-6">
                        <form @submit.prevent="quickAdd">
                            <label for="quickadd" class="control-label">{{ 'QuickProductAdd' | l }}</label>
                            <div class="input-group">
                                <input type="text" id="quickadd" class="form-control" v-model="quickAddSearch">
                                <div class="input-group-append">
                                    <button class="btn btn-secondary">
                                        <i class="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <span v-if="quickAddNoResult" class="text-danger">
                                {{ 'NoResult' | l }}
                            </span>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <table class="table table-borderless">
                            <thead class="thead-light">
                            <tr>
                                <th>{{ 'Category' | l }}</th>
                                <th>{{ 'Product' | l }}</th>
                                <th>{{ 'Description' | l }}</th>
                                <th class="text-right">{{ 'Quantity' | l }}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(p, i) in $v.order.products.$each.$iter" :key="i">
                                <td>{{ p.$model.categoryName }}</td>
                                <td>{{ p.$model.code }}</td>
                                <td>{{ p.$model.description }}</td>
                                <td width="100">
                                    <TextInput fieldName="quantity" :value="p.quantity"></TextInput>
                                </td>
                                <td class="text-right">
                                    <button class="btn btn-sm btn-danger" @click="removeProduct(p)">
                                        <i class="fas fa-times"></i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <ErrorMessage :value="$v.order.products"/>
                    </div>
                </div>
            </Panel>

            <div class="float-right mt-3">
                <inertia-link href="/orders" class="btn btn-secondary mr-2">{{ 'Cancel' | l }}</inertia-link>
                <button class="btn btn-primary" @click="sendOrder">{{ 'SendOrder' | l }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import Panel from '../../components/Panel.vue';
    import { required, minValue } from 'vuelidate/lib/validators'
    import TextInput from "../TextInput";
    import ErrorMessage from "../ErrorMessage";

    export default {
        components: {
            TextInput,
            Panel,
            ErrorMessage
        },
        props: {
            masterCategories: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                order: {
                    projectNumber: '',
                    managerEmail: '',
                    applicantName: '',
                    phone: '',
                    address: '',
                    city: '',
                    postalCode: '',
                    state: '',
                    country: '',
                    products: [],
                },
                categoryOptions: [],
                productOptions: [],
                masterCategoryId: null,
                categoryId: null,
                productId: null,
                quantity: 1,
                quickAddSearch: '',
                quickAddNoResult: false
            };
        },
        computed: {
            selectedCategory() {
                return _.find(this.categoryOptions, c => c.id === this.categoryId);
            },
            selectedProduct() {
                return _.find(this.productOptions, p => p.id === this.productId);
            },
            canAddToOrder() {
                return !!this.selectedProduct && this.quantity > 0;
            }
        },
        watch: {
            masterCategoryId() {
                this.fetchCategories(this.masterCategoryId);
            },
            categoryId() {
                this.fetchProducts(this.categoryId);
            }
        },
        methods: {
            fetchCategories(masterCategoryId) {
                abp.services.app.product.getByMasterCategory(masterCategoryId)
                    .then(data => this.categoryOptions = data);
            },
            fetchProducts(categoryId) {
                abp.services.app.product.getProductsByCategory(categoryId)
                    .then(data => this.productOptions = data);
            },
            addToOrder() {
                if (!this.canAddToOrder) return;

                const { id: productId, code, description } = this.selectedProduct;
                const quantity = parseInt(this.quantity,10);
                const categoryName = this.selectedCategory.name;

                let product = {
                    id: null, productId, categoryName, code, description, quantity
                };

                this.addProduct(product);
            },
            addProduct(product) {
                if (this.productExists(product.productId))
                    this.addQuantity(product.productId, product.quantity);
                else
                    this.order.products.push(product);
            },
            productExists(productId) {
                return !!_.find(this.order.products, op => op.productId === productId);
            },
            addQuantity(productId, quantity) {
                const product = _.find(this.order.products, op => op.productId === productId);

                if (product)
                    product.quantity += quantity;
            },
            quickAdd() {
                this.quickAddNoResult = false;
                abp.services.app.product.searchByProductCode(this.quickAddSearch)
                    .then(product => {
                        if (product) {
                            this.addProduct({ ...product, quantity: 1, productId: product.id, id: null });
                            this.quickAddSearch = '';
                        } else {
                            this.quickAddNoResult = true;
                        }
                    });
            },
            removeProduct(product) {
                this.order.products = _.filter(this.order.products, p => p !== product);
            },
            sendOrder() {
                this.$v.$touch();
                if (this.$v.$invalid) return;

                abp.services.app.order.create(this.order)
                    .then(() => {
                        this.$inertia.visit('/orders');
                    });
            }
        },
        validations: {
            quantity: {
                required,
                greaterThan: minValue(1)
            },
            order: {
                projectNumber: { required },
                applicantName: { required },
                managerEmail: { required },
                phone: { required },
                address: { required },
                city: { required },
                postalCode: { required },
                state: { required },
                country: { required },
                products: {
                    atLeastOneProduct: required,
                    $each: {
                        quantity: {
                            greaterThan: minValue(1)
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>
    td {
        vertical-align: middle;
    }
</style>
