<template>
  <Layout :title="'EditLocation' | l">
    
      <Form v-bind="{ request, validations, products, statutLog }" :onSave="save" />
   
  </Layout>
</template>

<script>
import Layout from "../../components/Layout";
import Form from "../../components/requests/RequestForm";
import { email, required, minValue } from "vuelidate/lib/validators";

const validations = {
  request: {
    date: {
      required,
    },
    degreeOfUrgencyId: {
      required,
      greaterThan: minValue(1),
    },
    entrepriseId: {
      required,
    },
    locationId: {
      required,
      greaterThan: minValue(1),
    },
    products: {
      atLeastOneProduct: required,
      $each: {
        quantity: {
          greaterThan: minValue(1),
        },
      },
     required,
    },
  },
};

export default {
  components: { Layout, Form },
  props: {
    request: {
      type: Object,
      required: true,
    },
    products: {
      type : Array,
      required: true,
    },
    statutLog: {
      type : Object,
      required: true
    }
  },
  data() {
    return { validations };
  },
  methods: {
    save(request) {
      return this.$inertia.put(`/requests/${request.id}`, request);
    },
  },
};
</script>

<style scoped>
</style>