<template>
        <span class="text-danger" v-if="value.$error">{{ error }}</span>
</template>

<script>
    import _ from "lodash";
    import {l} from "../localization";

    export default {
        props: ['fieldName', 'value'],
        computed: {
            error() {
                let error = _(this.value.$params)
                    .keys()
                    .filter(k => !this.value[k])
                    .first();
                
                if (!error) return '';

                return l(_.upperFirst(error), { fieldName: l(_.upperFirst(this.fieldName)), ...this.value.$params[error] });
            }
        }
    }
</script>

<style scoped>

</style>