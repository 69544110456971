<template>
<div>
    <Panel :title="'BaseInformation' | l">
        <div class="form-group">
            <label for="entrepriseName" class="control-label">{{ 'EntrepriseName' | l }}</label>
            <TextInput id="entrepriseName" :value="$v.entreprise.name"/>
        </div>

        <div class="form-group">
            <label for="address" class="control-label">{{ 'Address' | l }}</label>
            <TextInput id="address" :value="$v.entreprise.address" />
        </div>

        <div class="form-group">
            <label for="phone" class="control-label">{{ 'Phone' | l }}</label>
            <TextInput type="tel" id="phone" :value="$v.entreprise.phone" autocomplete="off"/>
        </div>

        <div class="form-group">
            <label for="email" class="control-label">{{ 'Email' | l }}</label>
            <TextInput type="email" id="email" :value="$v.entreprise.email" autocomplete="off" />
        </div>

        <div class="divider"></div>

        <!-- Liste de localisation associer à l'entreprise -->
        <div class="form-group">
            
        </div>

        <div class="clearfix">
            <LoadingButton btnClass="btn btn-primary float-right" :onClick="save">
                {{ 'Save' | l }}
            </LoadingButton>
        </div>
    </Panel>

    <!-- <ResetPasswordForm v-if="canChangePassword" :userId="user.id" /> -->
</div>
</template>

<script>

import Panel from "../../components/Panel";
import LoadingButton from "../LoadingButton";
import TextInput from "../TextInput";
import ErrorMessage from "../ErrorMessage";
// import ResetPasswordForm from "./ResetPasswordForm";
//Src : https://vue-select.org/
import vSelect from "vue-select";

export default {
    components: {
        // ResetPasswordForm,
         ErrorMessage, Panel, LoadingButton, TextInput, vSelect },
    props: {
        entreprise: {
            type: Object,
            default: () => ({})
        },
        localisations: {
             type: Array,
            default: () => []
        },
        onSave: {
            type: Function,
            default: () => {}
        },
        validations: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            password: {
                value: '',
                confirmation: ''
            },
            entrepriseSelected : "",
            isAddEntreprise : false,
        }
    },
    methods: {
        save() {
            this.$v.$touch();
            if (this.$v.$invalid) return;

            return this.onSave(this.entreprise);
        },
    },
    validations() {
        return this.validations;
    }
}
</script>

<style scoped lang="scss">

</style>
