<template>
    <nav :aria-label="'Pagination' | l">
        <ul class="pagination justify-content-end">
            <li class="page-item" v-for="link in links" :key="link.key" :disabled="!link.enabled" :class="{ disabled: !link.enabled, active: link.key === page }">
                <span class="page-link" @click="$emit('change', link.page)">{{ link.label }}</span>
            </li>
        </ul>
    </nav>
</template>

<script>
    import {l} from "../localization";

    export default {
        props: {
            page: {
                type: Number,
                default: 1
            },
            pageSize: {
                type: Number,
                default: 10
            },
            totalCount: {
                type: Number,
                required: true
            }
        },
        computed: {
            pageCount() {
                return Math.ceil(this.totalCount / this.pageSize);
            },
            pages() {
                return _.filter([this.page - 2, this.page - 1, this.page, this.page + 1, this.page + 2], i => i > 0 && i <= this.pageCount);
            },
            links() {
                let pages = _.map(this.pages, i => {
                    return { key: i, label: i, page: i, enabled: i !== this.page };
                });

                let links = [
                    { key: 'first', label: "«", page: 1, enabled: this.page !== 1 },
                    { key: 'previous', label: l('Previous'), page: this.page - 1, enabled: this.page !== 1 },
                    ...pages,
                    { key: 'next', label: l('Next'), page: this.page + 1, enabled: this.page < this.pageCount },
                    { key: 'last', label: "»", page: this.pageCount, enabled: this.page < this.pageCount }
                ];

                return links;
            }
        },
        methods: {
            getLink(page) {
                return `?page=${page}&pageSize=${this.pageSize}`;
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../assets/variables';

    .page-link {
        background-color: transparent;
        color: $secondary-color-dark;
        border-color: $secondary-color-dark;

        &:focus {
            box-shadow: $box-shadow;
        }
    }

    .page-item {
       
        cursor: pointer;
       
        &.disabled {
            cursor:not-allowed;
            .page-link {
                background-color: transparent;
                border-color: $secondary-color-dark;
            }
        }

        &.active {
            .page-link {
                background-color: $secondary-color-dark;
                color: white;
                border-color: $secondary-color-dark;
            }
        }
    }
</style>