<template>
    <div>
        <label for="image" class="control-label">Image</label>
        <div v-if="!previewMode">
            <input id="image" type="file" class="form-control" @change="upload">
        </div>
        <div v-else>
            <div class="d-flex justify-content-center">
                <img :src="imagePath" alt="" class="border-primary p-2 mr-2">
                <i class="fas fa-times" @click="quitPreview"></i>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        props: {
            imagePath: {
                type: String,
                default: ''
            },
            folderName: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                uploading: false,
            };
        },
        computed: {
            previewMode() {
                return !!this.imagePath;
            }
        },
        methods: {
            upload(ev) {
                this.uploading = true;

                let data = new FormData();

                data.append('file', ev.target.files[0]);
                data.append('folderName', this.folderName);

                axios.post('/upload', data)
                    .then(({data}) => {
                        this.$emit('update:imagePath', data.fileUrl);
                        this.uploading = false;
                    });
            },
            quitPreview() {
                this.$emit('update:imagePath', '');
            }
        }
    }
</script>

<style scoped lang="scss">
    img {
        max-width: 50%;
    }

    i {
        font-size: 24px;
    }
</style>