<template>
  <Modal @close="$emit('close')">

    <div class="statut">
      <label for="status">{{ "Status" | l }}</label>
      <select id="status" class="form-control" v-model="statusSelect">
        <option v-for="c in row.statuts.listStatut" :key="c.id" :value="c.id">
          {{ c.name }}
        </option>
      </select>
    </div>

    <StatusForm :statutLog="row.requestStatusLog" :statusId="statusSelect" :requestId="row.id"></StatusForm>
    <div slot="footer">
      <button
        type="button"
        class="btn btn-secondary mr-2"
        @click="$emit('close')"
      >
        {{ "Close" | l }}
      </button>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/core/Modal";
import { required, decimal } from "vuelidate/lib/validators";
import StatusForm from "@/components/requests/StatusForm";

export default {
  components: { Modal, StatusForm },
  props: {
    row: Object,
   editing: Boolean,
  },
  data() {
    return {
      statusSelect: 0,
    }
  },
  methods: {
    checkStatutSelect() {
      var statutId = this.statusSelect;
      if (this.row.requestStatusLog.cancelDate != null) {
          return 5;
        }
      if (this.row.requestStatusLog.creationDate === null) {
        return 1; // statut 1
      }
      if (this.row.requestStatusLog.pickUpBy === null) {
        return 2;
      }
      if (this.row.requestStatusLog.pickUpDate === null) {
        return 3;
      }
      if (this.row.requestStatusLog.arrivedDate === null || this.row.requestStatusLog.jobNumber === null) {
       
        return 4;
      } else {
        
        return 4;
      }
    },
  },
   beforeMount() 
   {
       this.statusSelect = this.checkStatutSelect();
   }
};
</script>

<style scoped>

</style>