<template>
    <Layout :title="'CreateUser' | l">
        <div class="row">
            <UserForm class="col-md-8 col-xl-6" :roles="roles" :user="user" :onSave="save" :validations="validations" :entreprises="entreprises"></UserForm>
        </div>
    </Layout>
</template>

<script>
    import Layout from "../../components/Layout";
    import UserForm from "../../components/users/UserForm";
    import {email, required} from "vuelidate/lib/validators";

    const validations = {
            user: {
                name: {
                    required
                },
                surname: {
                    required
                },
                userName: {
                    required
                },
                emailAddress: {
                    required,
                    emailValidation: email
                },
                password: {
                    required
                },
                roleNames: {
                    atLeastOneRole: required
                },
            }
        }
    ;

    export default {
        components: {Layout, UserForm},
        props: {
            user: {
                type: Object,
                required: true
            },
            roles: {
                type: Array,
                required: true
            },
            entreprises : {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                validations
            }
        },
        methods: {
            save(user) {
                return this.$inertia.post('/users', user);
            }
        }
    }
</script>

<style scoped>

</style>