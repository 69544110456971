<template>
    <Layout :title="'AddProductCategory' | l">
        <div class="row">
            <div class="col-md-10 col-xl-7">
                <ProductForm :category="category" :masterCategories="masterCategories" :on-save="save"></ProductForm>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from "../../components/Layout";
    import ProductForm from "../../components/products/ProductForm";

    export default {
        components: { Layout, ProductForm },
        props: {
            category: {
                type: Object,
                required: true
            },
            masterCategories: {
                type: Array,
                required: true
            }
        },
        methods: {
            save(category) {
                return this.$inertia.post('/products', category);
            }
        }
    }
</script>