<template>
  <Layout :title="'RequestList' | l">
    <div class="d-flex justify-content-between align-items-end mb-2">
      <div class="">
        <label for="search" class="control-label mb-0">{{
          "Search" | l
        }}</label>
        <input
          type="text"
          id="search"
          class="form-control"
          v-model="searchTerm"
          @input="refresh"
        />
      </div>

      <div>
        <label for="status" class="control-label mb-0">{{
          "Status" | l
        }}</label>
        <select
          id="status"
          class="form-control"
          v-model="statutId"
          @change="refresh"
        >
          <option value="0">{{ "AllNotCancel" | l }}</option>
          <option v-for="s in statuts" :value="s.id" :key="s.id">
            {{ s.name }}
          </option>
        </select>
      </div>

      <div>
        <label for="degreeOfUrgency" class="control-label mb-0">{{
          "DegreeOfUrgency" | l
        }}</label>
        <select
          id="degreeOfUrgency"
          class="form-control"
          v-model="degreeUrgency"
          @change="refresh"
        >
          <option value="0">{{ "NoFilter" | l }}</option>
          <option v-for="degree in degreeOfUrgencies" :value="degree.id" :key="degree.id">
            {{ degree.name }}
          </option>
        </select>
      </div>

      <div>
        <inertia-link href="/requests/create" class="btn btn-primary">
          <i class="fas fa-plus"></i>
          {{ "Add" | l }}
        </inertia-link>
      </div>
    </div>
    <Datatable v-bind="table" @sortingChanged="changeSorting" />
    <Pagination v-bind="pagination" @change="changePage" />
  </Layout>
</template>

<script>
import Vue from "vue";
import Layout from "../../components/Layout";
import Datatable from "../../components/Datatable";
import Pagination from "../../components/Pagination";
import { l } from "../../localization";
import EditableStatus from "../../components/requests/EditableStatus";

Vue.component("EditableStatus", EditableStatus);

export default {
  components: { Layout, Datatable, Pagination },
  props: {
    requests: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      required: true,
    },
    statutLog: {
      type: Object,
      default: () => ({}),
    },
    statuts: {
      type: Array,
      default: () => [],
    },
    degreeOfUrgencies: {
      type: Array,
      default: () => ({}),
    },
    search: String,
    statutId: Number,
    degreeUrgency: Number,
  },
  data() {
    return {
      searchTerm: this.search || "",
    };
  },
  computed: {
    sorting() {
      return this.pagination.sorting || "dateString ASC";
    },
    statusFilter() {
      return this.statutId;
    },
    degreeFilter() {
      return this.degreeUrgency;
    },
    table() {
      return {
        data: this.requests,
        columns: [
          { name: "dateString", label: "date", isSortable: true },
          {
            name: "entreprise.name",
            label: "entrepriseName",
            isSortable: false,
          },
          { name: "location.name", label: "locationName", isSortable: false },
          { name: "productsString", label: "productName", isSortable: false },
          { name: "statut", component: this.EditableStatus },
        ],
        actions: [
          {
            key: "location",
            label: "Location",
            className: "fas fa-map-marker-alt",
            link: (row) => `${row.location.coordonateGoogleMap}`,
          },
          {
            key: "edit",
            label: "Edit",
            className: "fas fa-pencil-alt",
            href: (row) => `/requests/${row.id}`,
          },
          {
            key: "delete",
            label: "Delete",
            className: "fas fa-trash-alt",
            action: (row) => this.delete(row),
          },
        ],
        sorting: this.pagination.sorting || "dateString ASC",
      };
    },
    EditableStatus() {
      return {
        name: "EditableStatus",
        props: {},
      };
    },
  },
  methods: {
    changePage(page) {
      this.pagination.page = page;
      this.refresh();
    },
    changeSorting(sorting) {
      this.pagination.sorting = sorting;
      this.refresh();
    },
    refresh: _.throttle(function () {
      this.$inertia.replace(
        `?page=${this.pagination.page}&sorting=${this.sorting}&search=${this.searchTerm}&statutId=${this.statusFilter}&degreeUrgency=${this.degreeFilter}`,
        { preserveState: true }
      );
    }, 200),
    delete(request) {
      abp.message
        .confirm(l("DeleterequestQuestion"), l("AreYouSure"))
        .then((result) => {
          if (result)
            return abp.services.app.request
              .delete({ id: request.id })
              .then(() => this.refresh());
        });
    },
  },
};
</script>

<style scoped>
</style>