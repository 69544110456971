<template>
    <Layout title="Une erreur est survenue">
        <p>Une erreur inattendue est survenue.</p> 
        
        <p>Veuillez réessayer. Si l'erreur persiste, n'hésitez pas à contacter le support.</p>
        <p>{{exception}}</p>
    </Layout>
</template>

<script>
    import Layout from "../components/Layout";
    
    export default {
        components: { Layout },
        props: {
            exception: Object
        }
    }
</script>

<style scoped>

</style>