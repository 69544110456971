/** Fonction très cool provenant de StackOverflow : https://stackoverflow.com/a/18234317
 Peut être utilisée pour formatter des strings de cette manière :

 'Bonjour {name}, que fais-tu {time}?'.format({ name: 'Marco', time: 'demain' });
 => 'Bonjour Marco, que fais-tu demain?'

 ou encore

 'Bonjour {0}, que fais-tu {1}?'.format('Marco', 'demain');

 pour le même résultat.
 */
export function format(str, ...params) {
    if (params.length) {
        let t = typeof params[0];
        let key;
        let args = ("string" === t || "number" === t) ?
            Array.prototype.slice.call(params)
            : params[0];

        for (key in args) {
            str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
        }
    }

    return str;
}