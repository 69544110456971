<template>
  <div class="wrapper">
    <div class="form-wrapper">
      <img src="../../../public/img/logo.png" alt="Glucides DT1" class="logo" />
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  mounted () {
   abp.message.confirm(
        "Une erreur est arrivée... Veuillez réessayer plus tard. Si le problème persiste alors communiquer avec le support. ",
        "Réessayer",
        (isConfirm) => {
          if (isConfirm) {
            this.$inertia.visit(`/Account/ResetPasswordView`);
          } else {
            this.$inertia.visit(`/Account/Login`);
          }
        })
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  width: 100vw;
  height: 100vh;
  background-color: white;

  padding: 10px;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (min-width: 576px) {
    padding-top: 100px;
  }
}

.logo {
  margin-bottom: 10px;
  width: 200px;
  align-self: center;
}

.form-wrapper {
  flex: 0 1 500px;
  display: flex;
  flex-direction: column;
}
</style>