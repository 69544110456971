<template>
    <Layout :title="'UserList' | l">
        <div class="d-flex justify-content-between align-items-end mb-2">
            <div class="">
                <label for="search" class="control-label mb-0">{{ 'Search' | l }}</label>
                <input type="text" id="search" class="form-control" v-model="searchTerm" @input="refresh">
            </div>
            <div>
                <inertia-link href="/users/create" class="btn btn-primary">
                    <i class="fas fa-plus"></i>
                    {{ 'Add' | l }}
                </inertia-link>
            </div>
        </div>
        <Datatable v-bind="table" @sortingChanged="changeSorting"/>
        <Pagination v-bind="pagination" @change="changePage"/>
    </Layout>
</template>

<script>
    import Layout from "../../components/Layout";
    import Datatable from "../../components/Datatable";
    import Pagination from "../../components/Pagination";
    import {l} from "../../localization";

    export default {
        components: {Layout, Datatable, Pagination},
        props: {
            users: {
                type: Array,
                default: () => []
            },
            pagination: {
                type: Object,
                required: true
            },
            search: String
        },
        data() {
            return {
                searchTerm: this.search || '',
            }
        },
        computed: {
            sorting() {
                return this.pagination.sorting || 'fullName ASC';
            },
            table() {
                return {
                    data: this.users,
                    columns: [
                        { name: 'fullName' },
                        { name: 'userName' },
                        { name: 'emailAddress' },
                        { name: 'isActive' },
                    ],
                    actions: [
                        { key: 'edit', label: 'Edit', className: 'fas fa-pencil-alt', href: row => `/users/${row.id}` },
                        { key: 'delete', label: 'Delete', className: 'fas fa-trash-alt', action: row => this.deleteUser(row) },
                    ],
                    sorting: this.pagination.sorting || 'fullName ASC'
                };
            }
        },
        methods: {
            changePage(page) {
                this.pagination.page = page;
                this.refresh();
            },
            changeSorting(sorting) {
                this.pagination.sorting = sorting;
                this.refresh();
            },
            refresh: _.throttle(function() {
                this.$inertia.replace(`?page=${this.pagination.page}&sorting=${this.sorting}&search=${this.searchTerm}`, { preserveState: true });
            }, 200),
            deleteUser(user) {
                abp.message.confirm(l("DeleteUserQuestion"), l("AreYouSure"))
                .then(result => {
                    if (result)
                        return abp.services.app.user.delete({id: user.id})
                            .then(() => this.refresh());
                });
            }
        }
    }
</script>

<style scoped>

</style>