<template>
  <Layout :title="'EditLocation' | l">
    <div class="row">
      <LocationForm
        v-bind="{ location, validations, entreprises }"
        :on-save="save"
        class="col-md-10 col-xl-7"
      />
    </div>
  </Layout>
</template>

<script>
import Layout from "../../components/Layout";
import LocationForm from "../../components/locations/LocationForm";
import { email, required } from "vuelidate/lib/validators";

const validations = {
  location: {
    name: {
      required,
    },
    address: {
      required,
    },
    town: {
      required,
    },
    postalCode: {
      required,
    },
    province: {
      required,
    },
    country: {
      required,
    },
    entrepriseId : {
      required,
    }
  },
};

export default {
  components: { Layout, LocationForm },
  props: {
    location: {
      required: true,
      type: Object,
    },
    entreprises: {
      type : Array,
      required: true,
    }
  },
  data() {
    return { validations };
  },
  methods: {
    save(location) {
      return this.$inertia.put(`/locations/${location.id}`, location);
    },
  },
};
</script>

<style scoped>
</style>