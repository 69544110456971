<template>
    <Layout :title="'CreateOrder' | l">
        <OrderForm :master-categories="masterCategories"/>
    </Layout>
</template>

<script>
    import Layout from "../../components/Layout";
    import OrderForm from "../../components/orders/OrderForm";

    export default {
        components: {Layout, OrderForm},
        props: {
            masterCategories: {
                type: Array,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>