import Vue from 'vue';

import { format} from "./utils/string";

const defaultSourceName = 'Iceberg';

abp.localization.defaultSourceName = defaultSourceName;

export function l(key, params) {
    let text = abp.localization.values[defaultSourceName][key];
    return format(!!text ? text : `[${key}]`, params);
}

Vue.filter('l', l);