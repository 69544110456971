<template>
  <Layout :title="'ChangePasseword' | l">
    <div class="row">
      <div class="col-md-8 col-xl-6">
        <Panel class="mb-3" :title="'ChangePasseword' | l">
          <div class="row">
            <div class="form-group col-12">
              <label for="passeword" class="control-label mb-0">{{ 'NewPasseword' | l }}</label>
              <TextInput id="password" :value="$v.changePasseword.passeword"/>
            </div>
            <div class="form-group col-12">
              <label for="confirmPassword" class="control-label mb-0">{{ 'NewPassewordConfirm' | l }}</label>
              <TextInput id="confirmPassword" :value="$v.changePasseword.confirmPasseword"/>
            </div>
          </div>
          

          <div class="text-right">
            <LoadingButton :onClick="save">{{ 'Save' | l }}</LoadingButton>
          </div>
        </Panel>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../components/Layout";
import Panel from "../../components/Panel";
import TextInput from "../../components/TextInput";
import LoadingButton from "../../components/LoadingButton";
 import {required} from "vuelidate/lib/validators";
import axios from 'axios';

const validations = {
   changePasseword: {
        passeword: {
          required
        },
        confirmPasseword: {
          required
        },
        id: {
          required: false
        }
      },
}

export default {
  components: {LoadingButton, TextInput, Panel, Layout},
  props: {
  }, 
  data() {
     return {
       changePasseword : {
         passeword : "",
         confirmPasseword: ""
       },
        validations : validations
     }
    
  },
  mounted () {
    this.changePasseword.id = this.id;
  },
  methods: {
    save() {
      this.$v.$touch();
            if (this.$v.$invalid) return;

     return this.$inertia.put(`/profile/changePasseword`, this.changePasseword);
    },
  },
  validations() {
     return this.validations
    }
  }

</script>

<style scoped lang="scss">
.control-label {
  margin-bottom: 0;
}
</style>