<template>
  <Layout :title="'LocationList' | l">
    <div class="d-flex justify-content-between align-items-end mb-2">
      <div class="">
        <label for="search" class="control-label mb-0">{{
          "Search" | l
        }}</label>
        <input
          type="text"
          id="search"
          class="form-control"
          v-model="searchTerm"
          @input="refresh"
        />
      </div>
      <div>
        <inertia-link href="/locations/create" class="btn btn-primary">
          <i class="fas fa-plus"></i>
          {{ "Add" | l }}
        </inertia-link>
      </div>
    </div>
    <Datatable v-bind="table" @sortingChanged="changeSorting" />
    <Pagination v-bind="pagination" @change="changePage" />
  </Layout>
</template>

<script>
import Layout from "../../components/Layout";
import Datatable from "../../components/Datatable";
import Pagination from "../../components/Pagination";
import { l } from "../../localization";

export default {
  components: { Layout, Datatable, Pagination },
  props: {
    locations: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      required: true,
    },
    search: String,
  },
  data() {
    return {
      searchTerm: this.search || "",
    };
  },
  computed: {
    sorting() {
      return this.pagination.sorting || "name ASC";
    },
    table() {
      return {
        data: this.locations,
        columns: [
          { name: "name", isSortable: true },
          { name: "address" , isSortable: true},
          { name: "town", isSortable: true },
        ],
        actions: [
          {
            key: "edit",
            label: "Edit",
            className: "fas fa-pencil-alt",
            href: (row) => `/locations/${row.id}`,
          },
          {
            key: "delete",
            label: "Delete",
            className: "fas fa-trash-alt",
            action: (row) => this.delete(row),
          },
        ],
        sorting: this.pagination.sorting || "name ASC",
      };
    },
  },
  methods: {
    changePage(page) {
      this.pagination.page = page;
      this.refresh();
    },
    changeSorting(sorting) {
      this.pagination.sorting = sorting;
      this.refresh();
    },
    refresh: _.throttle(function () {
      this.$inertia.replace(
        `?page=${this.pagination.page}&sorting=${this.sorting}&search=${this.searchTerm}`
      );
    }, 200),
    delete(location) {
      abp.message
        .confirm(l("DeleteLocationQuestion"), l("AreYouSure"))
        .then((result) => {
          if (result)
            return abp.services.app.location
              .delete({ id: location.id })
              .then(() => this.refresh());
        });
    },
  },
};
</script>

<style scoped>
</style>