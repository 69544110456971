<template>
  <Panel title="Reinitilisation de votre mots de passe">
    <form @submit.prevent="onSubmit">
      <div class="form-group">
        <label for="email" class="control-label">Courriel</label>
        <input type="text" id="email" class="form-control" v-model="email" />
      </div>

      <button type="submit" class="btn btn-block btn-primary">
        Réinitialiser
      </button>
      <span style="color: red">{{ error }}</span>
      <span style="color: gredd">{{ success }}</span>
    </form>
  </Panel>
</template>

<script>
import Panel from "../Panel";
import { l } from "../../localization";

export default {
  components: { Panel },
  props: ["submit"],
  data() {
    return {
      email: "",
      error: "",
      success: "",
    };
  },
  methods: {
    onSubmit() {
      abp.message
        .confirm(l("ResetPasswordQuestion"), l("AreYouSure"))
        .then((result) => {
          if (result) {
            this.$inertia.post(`/Account/ResetPassword?email=${this.email}`);
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
</style>