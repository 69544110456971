<template>
    <Layout :title="'Home' | l">
        Utilisez le menu de gauche pour accéder à la section de votre choix.
    </Layout>
</template>

<script>
    import Layout from "../components/Layout";

    export default {
        components: { Layout },
        props: ['message']
    }
</script>

<style scoped lang="scss">

</style>