<template>
  <div>
    <Panel :title="'BaseInformation' | l">
      <div class="form-group">
        <label for="firstName" class="control-label">{{
          "FirstName" | l
        }}</label>
        <TextInput id="firstName" :value="$v.user.name" />
      </div>

      <div class="form-group">
        <label for="lastName" class="control-label">{{ "LastName" | l }}</label>
        <TextInput id="lastName" :value="$v.user.surname" />
      </div>

      <div class="form-group">
        <label for="userName" class="control-label">{{ "UserName" | l }}</label>
        <TextInput id="userName" :value="$v.user.userName" autocomplete="off" />
      </div>

      <div class="form-group">
        <label for="email" class="control-label">{{ "Email" | l }}</label>
        <TextInput
          id="email"
          :value="$v.user.emailAddress"
          autocomplete="off"
        />
      </div>

      <div class="form-group" v-if="!user.id">
        <label for="password" class="control-label">{{ "Password" | l }}</label>
        <TextInput
          type="password"
          id="password"
          :value="$v.user.password"
          autocomplete="off"
        />
      </div>

      <div class="divider" v-if="isUserManage"></div>

      <div class="form-group" v-if="isUserManage">
        <p class="control-label">Rôles</p>

        <label v-for="role in roles" class="mr-3" :key="role.normalizedName">
          <input
            type="checkbox"
            :value="role.normalizedName"
            v-model="user.roleNames"
          />
          {{ role.displayName }}
        </label>
        <div>
          <ErrorMessage :value="$v.user.roleNames" />
        </div>
        <!-- <div class="form-group" v-if="hasAdminSelected">
                <label class="control-label">
                    <input id="receivesOrderEmails" type="checkbox" :value="$v.user.receivesOrderEmails" v-model="user.receivesOrderEmails" />
                    {{ 'ReceivesOrderEmails' | l }}
                </label>
            </div> -->

        <div class="form-group" v-if="hasUserSelected">
          <div class="divider"></div>
          <div class="form-group">
            <div class="row m-0" v-if="!isAddEntreprise">
              <label for="entrepriseSelected">{{
                "EntrepriseSelected" | l
              }}</label>
              <div class="col-10">
                <vSelect
                  label="name"
                  :options="entreprises"
                  :reduce="(entreprise) => entreprise.id"
                  :value="user.entrepriseId"
                  @input="setSelectedEntreprise"
                  placeholder
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!entrepriseSelected"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </vSelect>
              </div>
              <div class="col-2">
                <button
                  v-if="!isAddEntreprise"
                  type="button"
                  class="btn btn-success"
                  @click="addEntreprise()"
                >
                  Ajouter
                </button>
              </div>
              <span class="text-danger" v-if="user.entrepriseId <= 0"
                >Ce champ est requis</span
              >
            </div>
          </div>
          <div v-if="isAddEntreprise">
            <div class="form-group">
              <div class="row m-0">
                <label for="entrepriseName" class="control-label">{{
                  "EntrepriseName" | l
                }}</label>
                <div class="col-10 p-0">
                  <input
                    type="text"
                    id="entrepriseName"
                    class="form-control"
                    v-model="user.entrepriseName"
                    required
                  />
                </div>
                <div class="col-2">
                  <button
                    v-if="isAddEntreprise"
                    type="button"
                    class="btn btn-warning"
                    @click="closeAddEntreprise()"
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="phone" class="control-label">{{ "Phone" | l }}</label>
              <input
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                id="phone"
                class="form-control"
                v-model="user.entreprisePhone"
                required
              />
            </div>
            <div class="form-group">
              <label for="address" class="control-label">{{
                "Address" | l
              }}</label>
              <input
                type="text"
                id="address"
                class="form-control"
                v-model="user.entrepriseAddress"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix">
        <div class="text-right">
          <button
            v-if="isProfile"
            @click="changePasseword"
            class="btn btn-secondary mr-3"
          >
            Changer mon mot de passe
          </button>
          <LoadingButton btnClass="btn btn-primary float-right" :onClick="save">
            {{ "Save" | l }}
          </LoadingButton>
        </div>
      </div>
    </Panel>

    <!-- <ResetPasswordForm v-if="canChangePassword" :userId="user.id" /> -->
  </div>
</template>

<script>
import Panel from "../../components/Panel";
import LoadingButton from "../LoadingButton";
import TextInput from "../TextInput";
import ErrorMessage from "../ErrorMessage";
// import ResetPasswordForm from "./ResetPasswordForm";
//Src : https://vue-select.org/
import vSelect from "vue-select";

export default {
  components: {
    // ResetPasswordForm,
    ErrorMessage,
    Panel,
    LoadingButton,
    TextInput,
    vSelect,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    entreprise: {
      type: Object,
      default: () => ({ id: "", name: "", address: "", phone: "" }),
    },
    entreprises: {
      type: Array,
      default: () => [],
    },
    roles: {
      type: Array,
      default: () => [],
    },
    onSave: {
      type: Function,
      default: () => {},
    },
    validations: {
      type: Object,
      default: () => {},
    },
    isProfile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      password: {
        value: "",
        confirmation: "",
      },
      entrepriseSelected: "",
      isAddEntreprise: false,
      isUserManage: abp.auth.hasPermission("UserManagement"),
    };
  },
  computed: {
    hasAdminSelected() {
      return _.includes(this.user.roleNames, "ADMIN");
    },
    hasUserSelected() {
      return _.includes(this.user.roleNames, "USER");
    },
    canChangePassword() {
      return !!this.user.id;
    },
  },
  watch: {
    hasAdminSelected: function (isAdmin) {
      if (!isAdmin) this.user.receivesOrderEmails = false;
    },
  },
  methods: {
    setSelectedEntreprise(value) {
      this.user.entrepriseId = value;
    },
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      return this.onSave(this.user);
    },
    changePassword() {
      this.changePasswordSuccess = false;
      this.changePasswordError = false;

      return abp.services.app.user
        .resetPassword(this.password, { abpHandleError: false })
        .then(() => (this.changePasswordSuccess = true))
        .catch(() => (this.changePasswordError = true));
    },
    addEntreprise() {
      this.isAddEntreprise = true;
    },
    closeAddEntreprise() {
      this.isAddEntreprise = false;
    },
    changePasseword() {
      return this.$inertia.visit(`/profile/changePasseword`);
    },
  },
  validations() {
    return this.validations;
  },
};
</script>

<style scoped lang="scss">
</style>
