<template>
  <Panel title="Authentification">
    <form @submit.prevent="onSubmit">
      <div class="form-group">
        <label for="username" class="control-label">Usager</label>
        <input
          type="text"
          id="username"
          class="form-control"
          v-model="form.username"
        />
      </div>

      <div class="form-group">
        <label for="password" class="control-label">Mot de passe</label>
        <input
          type="password"
          id="password"
          class="form-control"
          v-model="form.password"
        />
      </div>

      <div class="form-group">
        <label>
          <input type="checkbox" v-model="form.remember" />
          Se souvenir de moi
        </label>
      </div>

      <button type="submit" class="btn btn-block btn-primary">
        Se connecter
      </button>
      <a class="reset-password mt-3" @click="resetPassword">
        Mot de passe oublié ?
      </a>
      <span style="color: red">{{ error }}</span>
    </form>
  </Panel>
</template>

<script>
import Panel from "../Panel";

export default {
  components: { Panel },
  props: ["submit"],
  data() {
    return {
      form: {
        username: "",
        password: "",
        remember: false,
      },
      error: "",
    };
  },
  methods: {
    onSubmit() {
     this.submit(this.form).catch((error) => {
        this.error = "Les informations d'authentification sont mauvaises";
      });
    },
    resetPassword() {
      return this.$inertia.visit(`/Account/ResetPasswordView`);
    },
  },
};
</script>

<style scoped lang="scss">
.reset-password {
  cursor: pointer;
  color: #212529;
  text-decoration: none;
}
.reset-password:hover {
  color: #303335;
}
.reset-password a {
  text-decoration: none;
}
</style>
