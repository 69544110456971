<template>
    <div>
        <div v-if="addingItem">
            <label for="newItemName" class="control-label">{{ newItemLabel | l }}*</label>
            <div class="input-group">
                <input id="newItemName" class="form-control" :value="value.value" @input="updateModel" :class="{ 'is-invalid': hasError }">
                <div class="input-group-append">
                    <button class="btn btn-secondary ml-2" :title="'Cancel' | l"
                            @click="cancelAdd">
                        <i class="fas fa-undo"></i>
                    </button>
                </div>
            </div>
        </div>
        <div v-else-if="managingItems">
            <div class="d-flex justify-content-between mb-2">
                <strong class="align-self-center">{{ 'Manage' + dictionary | l }}</strong>
                <button class="btn btn-light" @click="managingItems = false">
                    <i class="fas fa-arrow-left mr-2"></i><strong>{{ 'Back' | l }}</strong>
                </button>
            </div>
            <div v-if="!sortedItems.length">
                <div class="text-center">{{ 'NoData' | l }}</div>
            </div>
            <div v-for="item in sortedItems" :key="item.id" class="mb-2">
                <div class="input-group" v-if="editingItem === item">
                    <input type="text" class="form-control" v-model="item.value">
                    <div class="input-group-append">
                        <button class="btn btn-primary" @click="saveItem">
                            <i class="fas fa-check"></i>
                        </button>
                        <button class="btn btn-secondary" @click="cancelEditItem">
                            <i class="fas fa-ban"></i>
                        </button>
                    </div>
                </div>
                <div v-else class="d-flex align-items-center justify-content-between">
                    {{ item.value }}
                    <div class="btn-group">
                        <button class="btn btn-secondary" @click="editItem(item)">
                            <i class="fas fa-pencil-alt"></i>
                        </button>
                        <button class="btn btn-danger" @click="deleteItem(item)">
                            <i class="fas fa-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <label for="item" class="control-label">{{ label | l }}*</label>
            <div class="input-group">
                <select id="item" class="form-control" :value="value.id" @input="updateModel" :class="{ 'is-invalid': hasError }">
                    <option v-for="i in sortedItems" :value="i.id" :key="i.id">{{ i.value }}</option>
                </select>
                <div class="input-group-append">
                    <button class="btn btn-primary ml-2" :title="'Add' | l"
                            @click="addItem">
                        <i class="fas fa-plus"></i>
                    </button>
                    <button class="btn btn-secondary" :title="'ManageItems' | l"
                            @click="manageItems">
                        <i class="fas fa-ellipsis-v"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import TextInput from "../../TextInput";
    import {l} from "../../../localization";

    export default {
        components: {TextInput},
        props: {
            dictionary: {
                type: String,
                required: true
            },
            items: Array,
            value: {
                type: Object,
                required: true
            },
            label: {
                type: String,
                default: 'Item'
            },
            newItemLabel: {
                type: String,
                default: 'NewItem'
            },
            hasError: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                newItemValue: '',
                selectedItemId: this.value.id,
                addingItem: false,
                managingItems: false,
                previousItem: this.item,
                editingItem: null,
                deletedItems: []
            };
        },
        computed: {
            sortedItems() {
                return _.filter(this.items, i => !this.deletedItems.includes(i))
                    .sort((a, b) => a.value.localeCompare(b.value));
            }
        },
        methods: {
            updateModel({ target }) {
                let item;

                if (this.addingItem) {
                    item = { id: 0, value: target.value }
                } else {
                    item = _.find(this.items, i => i.id === parseInt(target.value, 10));
                }

                this.$emit('input', item);
            },
            addItem() {
                this.previousItem = {...this.value};
                this.$emit('input', { id: 0, value: '' });
                this.addingItem = true;
            },
            cancelAdd() {
                this.$emit('input', this.previousItem);
                this.addingItem = false;
            },
            manageItems() {
                this.managingItems = true;
            },
            saveItem() {
                return abp.services.app.dictionary.updateItem(this.editingItem)
                    .then(() => this.editingItem = null);
            },
            editItem(item) {
                this.previousEditValue = item.value;
                this.editingItem = item;
            },
            cancelEditItem() {
                this.editingItem.value = this.previousEditValue;
                this.editingItem = null;
            },
            deleteItem(item) {
                return abp.message.confirm(l("DeleteItemQuestion"), l("AreYouSure"))
                    .then(result => {
                        if (!result) return;

                        return abp.services.app.dictionary.deleteItem(item.id)
                            .then(() => this.deletedItems.push(item));
                    });
            }
        }
    }
</script>

<style scoped>

</style>