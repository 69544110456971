<template>
  <Layout :title="'ProductCategoryList' | l">
    <div class="d-flex justify-content-between align-items-end mb-2">
      <div class="">
        <label for="search" class="control-label mb-0">{{
          "Search" | l
        }}</label>
        <input
          type="text"
          ref="searchField"
          id="search"
          class="form-control"
          v-model="searchTerm"
          @input="refresh"
        />
      </div>
      <div>
        <inertia-link href="/products/create" class="btn btn-primary">
          <i class="fas fa-plus"></i>
          {{ "Add" | l }}
        </inertia-link>
      </div>
    </div>
    <Datatable v-bind="table" @sortingChanged="changeSorting" />
    <Pagination v-bind="pagination" @change="changePage" />
  </Layout>
</template>

<script>
import Layout from "../../components/Layout";
import Datatable from "../../components/Datatable";
import Pagination from "../../components/Pagination";

import { l } from "../../localization";

export default {
  components: { Layout, Datatable, Pagination },
  props: {
    search: {
      type: String,
      default: "",
    },
    pagination: {
      type: Object,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      searchTerm: this.search || "",
    };
  },
  computed: {
    table() {
      return {
        columns: [
          {
            name: "masterCategory.value",
            label: "MasterCategoryName",
            isSortable: true,
          },
          { name: "name", label: "CategoryName", isSortable: true },
        ],
        actions: [
          {
            key: "edit",
            label: "Edit",
            className: "fas fa-pencil-alt",
            href: (row) => `/products/${row.id}`,
          },
          {
            key: "delete",
            label: "Delete",
            className: "fas fa-trash-alt",
            action: (row) => this.deleteRow(row),
          },
        ],
        data: this.products,
        sorting: this.pagination.sorting || "masterCategory.value ASC",
      };
    },
    sorting() {
      return this.pagination.sorting || "masterCategory.value ASC";
    },
  },
  methods: {
    changePage(page) {
      this.pagination.page = page;
      this.refresh();
    },
    changeSorting(sorting) {
      this.pagination.sorting = sorting;
      this.refresh();
    },
    refresh: _.throttle(function () {
      this.$inertia.replace(
        `?page=${this.pagination.page}&sorting=${this.sorting}&search=${this.searchTerm}`,
        { preserveState: true }
      );
    }, 200),
    deleteRow(row) {
      abp.message.confirm(
        l("ConfirmRowDeletion"),
        l("AreYouSure"),
        (result) => {
          abp.services.app.product
            .delete({ id: row.id })
            .then(() => this.refresh());
        }
      );
    },
  },
};
</script>