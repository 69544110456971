<template>
    <Layout :title="'EditProductCategory' | l">
        <div class="row">
            <div class="col-md-10 col-xl-7">
                <ProductForm :masterCategories="masterCategories" :category="category" :on-save="save"></ProductForm>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from "../../components/Layout";
    import ProductForm from "../../components/products/ProductForm";

    export default {
        components: { Layout, ProductForm },
        props: {
            masterCategories: {
                type: Array,
                required: true
            },
            category: {
                type: Object,
                required: true
            }
        },
        methods: {
            save(category) {
                return this.$inertia.put(`/products/${category.id}`, category);
            }
        }
    }
</script>
