<template>
  <div>
    <div v-if="statusId === 1">
      <div class="form-group col-md">
        <label for="CreationDate">{{ "CreationDate" | l }}</label>
        <TextInput
          :type="'date'"
          id="CreationDate"
          :value="$v.statutLog.creationDate"
          @focus="statutLog.creationDate ? statutLog.creationDate : new Date()"
        />
      </div>
    </div>

    <div v-if="statusId === 2">
      <div class="form-group col-md">
        <label for="pickUpBy">{{ "PickUpBy" | l }}</label>
        <TextInput id="pickUpBy" :value="$v.statutLog.pickUpBy"></TextInput>
      </div>
    </div>

    <div v-if="statusId === 3">
      <div class="form-group col-md">
        <label for="pickUpDate">{{ "PickupDate" | l }}</label>
        <TextInput
          :type="'date'"
          id="pickUpDate"
          :value="$v.statutLog.pickUpDate"
          @focus="statutLog.pickUpDate ? statutLog.pickUpDate : new Date()"
        />
      </div>
    </div>

    <div v-if="statusId === 4">
      <div class="form-group col-md">
        <div class="row">
          <div class="col-6">
            <label for="arrivedDate">{{ "ArrivedDate" | l }}</label>
            <TextInput
              :type="'date'"
              id="arrivedDate"
              :value="$v.statutLog.arrivedDate"
              @focus="
                statutLog.arrivedDate ? statutLog.arrivedDate : new Date()
              "
            />
          </div>
          <div class="col-6">
            <label for="jobNumber">{{ "JpbNumber" | l }}</label>
            <TextInput
              id="jobNumber"
              :value="$v.statutLog.jobNumber"
            ></TextInput>
          </div>
        </div>
      </div>
    </div>

    <div v-if="statusId === 5">
      <div class="form-group col-md">
        <div class="row">
          <div class="col-6">
            <label for="cancelDate">{{ "CancelDate" | l }}</label>

            <TextInput
              :type="'date'"
              id="cancelDate"
              :value="$v.statutLog.cancelDate"
              @focus="statutLog.cancelDate ? statutLog.cancelDate : new Date()"
            />
          </div>
          <div class="col-6">
            <label for="cancelBy">{{ "CancelBy" | l }}</label>
            <TextInput id="cancelBy" :value="$v.statutLog.cancelBy"></TextInput>
          </div>
        </div>
        <div class="mt-3">
          <label for="cancelFor">{{ "CancelFor" | l }}</label>
          <TextInput id="cancelFor" :value="$v.statutLog.cancelFor"></TextInput>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <inertia-link href="/requests" class="btn btn-secondary mr-2">{{
        "Cancel" | l
      }}</inertia-link>
      <button class="btn btn-primary" @click="save()">
        {{ "sendStatus" | l }}
      </button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { required, minValue } from "vuelidate/lib/validators";
import TextInput from "../TextInput";
import { InertiaLink } from "@inertiajs/inertia-vue";

export default {
  components: {
    TextInput,
    InertiaLink,
  },
  props: {
    statusId: {
      type: Number,
      default: 0,
    },
    requestId: {
      type: Number,
      default: 0,
    },
    statutLog: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      statusSelect: this.statusId,
    };
  },
  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$inertia.put(`/requests/statut/${this.requestId}`, this.statutLog);
      location.reload();
    },
    setDateFormat(value) {
      if (value != null) {
        var d = new Date(value),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        value = [year, month, day].join("-");
        return value;
      }
    },
  },
  beforeMount() {
    this.statutLog.creationDate = this.setDateFormat(
      this.statutLog.creationDate
    );
    this.statutLog.pickUpDate = this.setDateFormat(this.statutLog.pickUpDate);
    this.statutLog.arrivedDate = this.setDateFormat(this.statutLog.arrivedDate);
    this.statutLog.cancelDate = this.setDateFormat(this.statutLog.cancelDate);
  },
  // Src :: https://vuelidate.js.org/#sub-dynamic-validation-schema
  validations() {
    if (this.statusId === 1) {
      return {
        statutLog: {
          creationDate: { required },
        },
      };
    } else if (this.statusId === 2) {
      return {
        statutLog: {
          pickUpBy: { required },
        },
      };
    } else if (this.statusId === 3) {
      return {
        statutLog: {
          pickUpDate: { required },
        },
      };
    } else if (this.statusId === 4) {
      return {
        statutLog: {
          arrivedDate: { required },
          jobNumber: { required },
        },
      };
    } else if (this.statusId === 5) {
      return {
        statutLog: {
          cancelBy: { required },
          cancelFor: { required },
          cancelDate: { required },
        },
      };
    }
  },
};
</script>

<style lang="scss" scoped>
</style>