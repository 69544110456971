<template>
  <div>
    <div v-if="!editing" class="d-flex justify-content-between">
      {{ row.statutName }}
      <div v-if="hasPermission">
      <i v-if="saving" class="fas fa-sync-alt fa-spin align-self-center"></i>
      <i
        v-else
        class="fas fa-pencil-alt align-self-center"
        @click="editing = true"
        ></i>
      </div>
     
    </div>
    <div v-else>
      <StatutModal :row="row" :editing="editing" @close="editing = false"></StatutModal>
    </div>
  </div>
</template>

<script>
import StatutModal from "../../components/requests/StatutModal";
export default {
  components: {
    StatutModal,
  },
  props: {
    row: Object,
  },
  data() {
    return {
      editing: false,
      saving: false,
      hasPermission : abp.auth.hasPermission('UserManagement')
    };
  },
  methods: {
    save() {
      this.editing = false;
      this.saving = true;
      this.onSave(this.row).then(() => (this.saving = false));
    },
    cancel() {
      this.editing = false;
      this.row.status = this.previousStatus;
    },
  },
};
</script>

<style scoped>
.fas {
  cursor: pointer;
}
</style>