require('./assets/app.scss');
require("./localization");

Vue.config.productionTip = false;

import { InertiaApp } from '@inertiajs/inertia-vue';
import Vue from 'vue';
import Vuelidate from 'vuelidate';

import moment from 'moment';
moment.locale('fr');

Vue.use(InertiaApp);
Vue.use(Vuelidate);

const app = document.getElementById('app');

new Vue({
  render: h => {
    let parsedPage = JSON.parse(app.dataset.page);

    let initialPage = {
      ...parsedPage,
      props: transformProps(parsedPage.props)
    };

    return h(InertiaApp, {
      props: {
        initialPage: initialPage,
        resolveComponent: name => require(`./pages/${name}`).default,
        transformProps
      },
    })
  },
}).$mount(app);

function transformProps(props) {
  if (props.controller)
    return {
      ...props.controller,
      ...props.share,
      ...props.with
    };

  return props;
}