<template>
    <nav class="menu">
        <ul>
            <li v-for="link in linkShow" :key="link.url" :class="{ active: isActive(link) }">
                <inertia-link :href="link.url">
                    <i :class="link.icon"></i>
                    {{ link.title }}
                </inertia-link>
            </li>
        </ul>
    </nav>
</template>

<script>
    import { InertiaLink } from '@inertiajs/inertia-vue';

    const links = [
         { show: false, url: '#first' },
        // Accessible à tout le monde / client
        { title: "Profil", url: '/users/profile', icon: 'fas fa-user-circle', show: true },
        { title: "Liste des requêtes", url: '/requests', icon: 'fas fa-list', show: true },
     
        // Super admin
        { title: "Gestion des usagers", url: '/users', icon: 'fas fa-users', show: abp.auth.isGranted('UserManagement') },
        { title: "Gestion des produits", url: '/products', activePattern: /\/products(\/.*)?/i, icon: 'fas fa-certificate', show: abp.auth.isGranted('ProductManagement') },
        { title: "Liste des entreprises", url: '/entreprises', icon: 'fas fa-building', show: abp.auth.isGranted('UserManagement')  },

        // Usager admin
        { title: "Liste des clients", url: '/clients', icon: 'fas fa-address-book', show: abp.auth.isGranted('ClientManager') && !abp.auth.isGranted('UserManagement')  },
        { title: "Liste des localisations", url: '/locations', icon: 'fas fa-map', show: abp.auth.isGranted('LocalisationManager')  },
    
        { show: false, url: '#last' }
    ];

    export default {
        components: { InertiaLink },
        data() {
            return { links };
        },
        methods: {
            isActive(link) {
                let pathname = window.location.pathname.toLowerCase();

                if (link.activePattern)
                    return !!pathname.match(link.activePattern);

                return pathname === link.url.toLowerCase();
            }
        },
        computed: {
            linkShow : function(){
                return this.links.filter(x => x.show === true)
            }
        }
    }
</script>